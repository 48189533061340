import api from './';

export interface GenerateResponse {
  data: string;
}

const excelGenerate = api.injectEndpoints({
  endpoints: (build) => ({
    generateExcelM6C: build.query<GenerateResponse, void>({
      query: () => ({
        url: 'excel-generates/m6c',
      }),
    }),
    generateExcelStrabacino: build.query<GenerateResponse, void>({
      query: () => ({
        url: 'excel-generates/strabacino',
      }),
    }),
    generateExcelStrabacinoNo: build.query<GenerateResponse, void>({
      query: () => ({
        url: 'excel-generates/strabacinoNo',
      }),
    }),
  }),
});

export const {
  useLazyGenerateExcelM6CQuery,
  useLazyGenerateExcelStrabacinoQuery,
  useLazyGenerateExcelStrabacinoNoQuery,
} = excelGenerate;
